<div class="card text-md-center card-shadow rounded-1 p-2 p-md-4 h-100  shop-item-card-root-container">
    <div class="item-container">
        @if(cartService.eachItemQuantityInCart(item)!= 0){
        <div class="bg-secondary text-light rounded-circle text-center"></div>
        <span class="bg-secondary rounded-circle text-center text-light" class="item-qty-counter">
            {{cartService.eachItemQuantityInCart(item)}}
        </span>
        }
        @if(this.hasPromotion==true ){
        @if (currentPromotion!==null && currentPromotion !== undefined
        && this.currentPromotion.show_special_offer_badge==1 ) {
        <div class="offer-icon-container">
            <img src="assets/images/icons/special-offer-corner.svg">
        </div>
        }
        }


        <img [src]="item.images !== null ? imageUrl+((item.defaultImage !== null)? item.defaultImage:item.images[0]): 'assets/images/no-image-available.png'"
            onerror="this.src='assets/images/no-image-available.png'" width="150" height="150"
            style="object-fit: contain;" role="button" (click)="onSelect()"
            class="m-md-auto mb-md-4 dish-img item-img_{{item.saleItemId}}" alt="">


        <!-- <img src="{{ assetsUrl }}/images/product/{{ item.images[0] }}" width="150" height="150"
            style="object-fit: contain;" role="button" (click)="onSelect()"
            class="m-md-auto rounded-circle mb-md-4 dish-img" alt=""> -->

        <div
            class="dish-detail ps-3 ps-sm-4 ps-md-0 ms-sm-2 ms-md-0 d-flex flex-wrap flex-sm-nowrap d-md-block align-items-center justify-content-between">
            <div class="item-name-price">
                <h5 class="item-name pb-sm-1">{{item.name}}</h5>
                <h3 class="font-secondary mb-sm-0 mb-md-3 amount" style="margin-bottom: .5rem !important;">
                    {{currencySymbol}}{{item.unitPriceWithTax | number :
                    '1.2-2'}}
                </h3>
            </div>

            @if(this.hasPromotion==true && currentPromotion!==null && currentPromotion !== undefined) {
            <div class="offer-description-desk">
                {{getPromotionBanner()}}
            </div>
            }@else {
            <!-- Dummy div to keep the alignment -->
            <div class="">&nbsp;</div>
            }
            @if(!this.outOfStockItemIds.includes(this.item.id)){
            <div class="d-flex justify-content-between align-items-center col-12 col-sm-auto">
                @if((item.itemType==2 && item.isCustomizable === 1) || item.itemType==3) {
                <button class="btn btn-outline-dark rounded-pill py-1 px-2 py-sm-2 px-sm-3 font-secondary text-truncate"
                    (click)="onCustomize()"><img src="assets/icons/chef_icon.svg" width="24"
                        class="me-sm-2 custom-icon white-icon" alt="">
                    Customise
                </button>
                } @else {

                <!-- <div class="py-1 px-2 py-sm-2 px-sm-3 font-secondary text-truncate d-none"></div> -->
                }
                @if (item.itemType !== 3) {
                <div class="ms-auto">
                    <button
                        class="col-auto col-lg col btn btn-primary rounded-pill font-secondary py-1 py-sm-2 px-4 ms-2"
                        (click)="onAdd()">Add</button>
                </div>
                }
            </div>
            }
            @else {
            <div class="d-flex justify-content-between align-items-center col-12 col-sm-auto">
                @if (!isItemAvailable(item)) {
                <button class="btn rounded-1 py-1 px-2 py-sm-1 px-sm-3 font-secondary text-danger fs-5">
                    {{message}}
                </button>
                }
            </div>
            }
        </div>
    </div>
    @if(this.hasPromotion==true && currentPromotion!==null && currentPromotion !== undefined) {
    <div class="mt-3 offer-description-mob">
        {{getPromotionBanner()}}
    </div>
    }
</div>